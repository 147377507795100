import styled from 'styled-components'
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const ConsiderationsForTesting = styled.div`
.headerwraphead {
	.containerWidth {
		.topnav {
			button {
				@media ${device.mobileMedium} {
					top: 310px;
				}
			}
		}
	}
}

.c-hide-references {
    .extlink {
      &:focus {
        text-decoration: underline;
      }
	  &:focus-within {
        text-decoration: underline;
      }
      &:focus-visible {
        text-decoration: underline;
      }
    }
}


.c-hide-references{
  ul{
    li{
      a{
        color: rgb(0, 255, 217);
        text-decoration: underline;
      }
    }
  }
}
.header-inner {
	.navbar-nav {
		.navitemstyle {
			&:first-child {
				@media ${device.laptopL} {
					margin-right: 0;
				}
			}

			@media ${device.desktopsignup} and  ${device.midDesktopMax} {
				padding: 0 10px;
			}
		}
	}

	.navbar {
		.navbar-collapse {
			.navbar-nav {
				@media ${device.mobileMedium} {
					min-height: 450px;
				}
			}
		}
	}
}

.topnav {
	button {
		@media ${device.mobileStart} and ${device.minideskend} {
			width: 80%;
			top: 400px;
		}
	}
}

	@media ${device.laptopMedium} {
		.topnav {
			background-color: transparent;
		}
		.headerwraphead {
			.header-inner {
				padding-top: 0;
			}
		}

	}
	@media ${device.ipadLandscapemin} and ${device.desktopmenu} {
		.topnav {
			background-color: transparent;

			button {
				top: 430px;
			}
		}
		.headerwraphead {
			.header-inner {
				padding-top: 0;
			}
		}

	}
	&.hcp-consideration-container {

		.navbar-nav {
			.footer-list {

			  &:first-child {
				.navlinkstyle {
				  &:focus {
					text-decoration: underline;
				  }
				}
			  }
			}
		}

    .clinical-trials-banner {
      height: 100%;
			.internal-banner {
				@media ${device.mobileMedium} {
					height: 360px;
				}
        @media ${device.tablet} and ${device.laptopMedium} {
					height: 500px;
				}

				&:after {
          top: unset;
					@media ${device.ipadLandscape} {
						height: 32px;
					}
				}
        .gatsby-image-wrapper-constrained{

          @media ${device.laptopMedium}{
            width: 100%;
            height: 100%;
            object-position: 90% 0%;
          }
          @media ${device.desktopsignup}{
            width: 100%;
            margin-top: -2px;
          }
          @media ${device.largeDesktop}{
            height: 380px;
          }
          @media ${device.extraLargeDesktop}{
            height: 460px;
          }
        }
        div.internal-banner__contant{
          div.o-container{
            max-width: 1188px !important;
            padding: 0 3rem;
            @media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
              padding: 0 6rem;
            }
            h1{
              @media ${device.ipadLandscapemin} {
                padding: 0 0 0 25px;
              }
            }
          }
        }
        .internal-banner__image{
          .desktop-image{
            height: 100%;
              .media--image{
                height: 100%;
              }
            img{
              display: block;
              object-fit: cover;
              width: 100%;
              height: 100%;
              object-position: 90% 0%;

              @media ${device.laptopPros}{
                display: none;
              }
              @media ${device.tablet}{
                object-position: 90% 0%;
              }
              @media ${device.ipadLandscapemin} {
                width: 100%;
              }
            }
          }
          .mobile-image{
            .media--image{
              height: 100%;
            }

            img{
              display: none;
              @media ${device.laptopPros}{
                display: block;
                object-fit: cover;
                width: 100%;
                height: 100%;
                object-position: center;
              }
            }
          }
        }
			}
    }

    // .footercontainer {
    //   padding: 0 0 6rem;
    //   @media ${device.mobileMedium} {
    //     margin: -110px auto 0px;
    //   }
    //   @media ${device.tablet} and ${device.laptopMedium} {
    //     margin: -199px auto 0px;
    //   }
    //   @media ${device.ipadLandscapemin} and ${device.minideskend} {
    //     margin: 156px auto 0px;
    //   }
    //   @media ${device.laptop} {
    //     margin: 39px auto 0px;
    //   }
    //   @media ${device.desktopsignup} {
    //     margin: 0;
    //   }
		// 	.row{
		// 		padding-top: 0;
		// 	}
    // }
    // footer {
		// 	max-width: 1188px;
		// 	margin-left: auto;
		// 	margin-right: auto;
		// 	padding: 0 3rem;
		// 	@media ${device.laptopMedium} {
		// 		padding: 0 5rem;
		// 	}
		// 	@media ${device.ipadLandscape} {
		// 		max-width: 718px;
		// 	}
		// 	@media ${device.laptopPros} {
		// 		padding: 0 1rem;
		// 	}
    // }
	}

	.consideration-testing {
    .o-header--h1 {
				padding-left: 25px;
				@media ${device.ipadLandscape} {
					padding-left: 0;
				}
    }
    &:before{
      @media ${device.desktopsignup} and ${device.midDesktopMax} {
        width: 40px;
        left: 0px;
      }
    }
    .emerging-science-when-you-test{
      padding: 0px 2.7rem;
    }
    .internal-banner__contant {
			position: absolute;
			left: 0;
			width: 100%;
			top: 50%;
			transform: translateY(-50%);
			z-index: 99;

			@media ${device.ipadLandscape} {
				top: 40px;
				transform: translateY(0);
			}
    }

    .o-container-considerations {
			max-width: 1188px;
			margin-left: auto;
			margin-right: auto;
			padding: 0 3rem;

			.c-genetic--american {
				padding: 35px 0 0;
				margin-bottom: 8rem;
				margin-top: 8rem;
				max-width: 950px;
				margin-left: auto;
				margin-right: auto;

				@media ${device.ipadLandscape} {
					padding: 0;
					margin-bottom: 5rem;
				}

				.o-paragraph {
					font-size: 4.2rem;
					font-weight: 600;
					line-height: 5rem;
					color: #000000;
					text-align: center;

					@media ${device.laptopMedium} {
						font-size: 2.5rem;
						line-height: 3rem;
					}
				}
			}

			.c-genetic--test {
				padding: 35px 60px;
				position: relative;
				background-color: #f7f7f7;
				box-shadow: 0 4px 6px #cecece;
				margin-bottom: 8rem;
				margin-top: 8rem;
				max-width: 950px;
				margin-left: auto;
				margin-right: auto;
				margin: 0 70px;

				.o-paragraph {
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;
					color: #000000;
				}

				.o-paragraph-inner {
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;
					color: #000000;

					span {
						font-weight: bold;
					}
				}

				.o-text-link {
					text-transform: unset;
					font-size: 2.2rem;
					line-height: 3.2rem;
					text-decoration: none;
					position: relative;
					display: inline-block;
					color: #000000;
					font-weight: 500;
					padding-bottom: 3px;
					margin-top: 14px;

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
    				line-height: 2.6rem;
					}
					a {
						@media ${device.laptopMedium} {
							font-size: 1.8rem;
    					line-height: 2.2rem;
						}
					}
				}

				p {
					a {
						color: #8000BE;
						font-weight: 500;
					}
				}

				.thirdPartyLink {
					cursor: pointer;
				}

				.o-text-link {
					a {
						color: #8000BE;
						text-decoration: underline;
					}

				}

				@media ${device.ipadLandscape} {
					margin: 40px 0 0;
					padding: 20px;
				}

      }

			.c-genetic--test::before {
				content: "";
				width: 16px;
				height: 100%;
				display: block;
				background: linear-gradient(-180deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
				position: absolute;
				top: 0;
				right: 100%;
				box-shadow: 0 4px 6px #cecece;

				@media ${device.ipadLandscape} {
					content: "";
					width: 100%;
					height: 10px;
					background: linear-gradient(-188deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
					top: 0;
					left: 0;
					right: unset;
					box-shadow: none;
				}
			}

			@media ${device.midDesktopMax} {
				padding: 0 5rem;
			}

			@media ${device.ipadLandscape} {
				max-width: 718px;
				font-size: 2rem;
				line-height: 2.6rem;
			}

			@media ${device.laptopPros}{
				padding: 0 3rem;
			}
    }
	}

	.internal-banner {
		&:after {
			top: unset;

		}
	}

	.emerging-science-when-you-test {
    margin: 80px auto 0;

		@media ${device.laptopMedium} {
			margin: 20px auto 0;
		}

    @media ${device.laptopPros} {
      padding: 0 2.7rem;
    }
    .o-inner {
      padding: 0 100px;

		@media ${device.laptopMedium} {
			padding: 0;
		}

			.specialist-infobox.info-box {
				margin-bottom: 120px;
				padding: 32px 80px;

				@media ${device.laptopMedium} {
					margin-bottom: 50px;
					padding: 24px 42px;
				}

				.media--image {
					@media ${device.minimumDesk} {
						padding-bottom: 80px;
					}
				}
			}

			.info-box {
				display: -ms-flexbox;
				display: flex;
				-webkit-align-items: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				background-color: #300064;
				padding: 27px 102px;
				position: relative;
				margin: auto;
				width: 100%;
				text-align: center;

				@media ${device.laptopMedium} {
					display: block;
				}

				img {
					position: absolute;
					left: 100px;
					max-width: 100%;

					@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
						top: 20%;
					}

					@media ${device.laptopMedium} {
						display: block;
    				margin: 0 auto 10px;
						width: 45px;
						left: 0;
						right: 0;
						text-align: center;
						position: unset;
            top: 25px;
					}
				}

				.o-paragraph {
					padding: 0 0 0 120px;
					font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
					font-weight: 700;
					text-align: left;
					color: #fff;
					line-height: 3.2rem;
					font-size: 2.2rem;
					margin: 0;

					@media ${device.laptopMedium} {
						padding: 0;
						font-family: "Gotham", Arial, Helvetica, sans-serif;
						line-height: 2rem;
						font-size: 2rem;
						display: inline-block;
						text-align: center;
						margin-top: 10px;
					}

					strong {
						color: #00FFD9;
						display: inline-block;
						font-size: 2.7rem;
						padding-bottom: 12px;
						font-weight: 600;

						@media ${device.laptopMedium} {
							line-height: 2.2rem;
							padding-bottom: 32px;
							font-size: 2rem;
						}
					}

				}

				.link-right-arrow {
					cursor: pointer;
					background-color: #fff;
					width: 40px;
					height: 40px;
					line-height: 46px;
					text-align: center;
					border-radius: 50%;
					margin: 5px 0 0 28px;

					@media ${device.laptopMedium} {
						display: flex;
						margin: 20px auto 0;
						justify-content: center;
						align-items: center;
						position: initial;
					}

					@media ${device.ipadLandscapemin} {
						width: auto;
						height: auto;
						line-height: unset;
					}

					&:hover {
						transform: scale(1.2);
						transition: ease 5ms;
					}

					.media--image {
						@media ${device.ipadLandscapemin} {
							width: 40px;
							padding-bottom: 0;
						}

						img {
							display: block;
							margin: 13px auto;
							max-width: 100%;
							position: static;
							left: 0;

							@media ${device.laptopMedium} {
								margin: 0;
								width: auto;
							}
						}
					}
				}
			}
		}
	}


	.c-gene-modification {
		padding-bottom: 0px;
		padding-top: 60px;

		@media ${device.ipadLandscape} {
			text-align: center;
			padding: 40px 0 !important;
		}

    .faq-block {
			flex-wrap: inherit;
			align-items: center;
			margin: 0 auto;
			max-width: 1440px;
			padding-top: 44px;
			display: flex;
			position: relative;

			@media ${device.ipadLandscape} {
				display: block;
			}

			.genes-faq-content__left {
				width: 50%;
				margin: 0 20px 0 0;
				@media ${device.ipadLandscape} {
					width: 100%;
					margin: 0;
					padding-left: 25px;
					padding-right: 25px;
				}

				h2 {
					text-align: left;
					margin: 0;
					color: #000000;
					font-family: "Gotham", Arial, Helvetica, sans-serif;

					@media ${device.ipadLandscape} {
						margin-top: 0px !important;
						font-size: 2.2rem;
						padding-bottom: 20px;
						line-height: 3rem;
						text-align: center;
					}
				}
      }

			.genes-faq-media__right {
				display: grid;
				align-items: center;
				width: 50%;

				@media ${device.ipadLandscape} {
					width: 100%;
					padding-top: 20px;
					padding: 20px 0 0;

					.gatsby-image-wrapper {
						width: 100%;
						vertical-align: middle;
						border-style: none;
					}
				}
			}

			.o-header--h2 {
				font-size: 4.2rem;
				font-weight: 600;
				line-height: 5rem;
				color: #000000;
			}

			.o-padding-left-full {
				padding-left: calc((100% - 1042px) / 2);
				@media ${device.ipadLandscape} {
					padding-left: 25px;
				}
			}

			h2 {
				color: #000000;
				text-align: left;
				padding-bottom: 40px;
			}

			.o-text--book {
				font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
			}

			.o-paragraph {
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: #000000;
				text-align: left;

				@media ${device.ipadLandscape} {
					font-size: 1.8rem;
					line-height: 2.6rem;
					text-align: center;
				}
			}

			a {
				color: #8000BE;
				text-decoration: underline;
			}
    }
	}

	// .footer {
	// 		@media ${device.mobileMedium} {
	// 			margin-top: 0;
	// 		}

	// 		@media ${device.ipadLandscape} {
	// 			margin-top: 0;
	// 		}
	// }

`
export const FooterCalloutContainer = styled.div`

	.padding-cards-fix {
		@media ${device.ipadLandscape} {
			max-width: 718px;
			padding: 0 2rem;
			font-size: 2rem;
			line-height: 2.6rem;
		}

		@medai ${device.laptopPros} {
			padding: 0 3rem;
		}
	}

	.steptogenetic-footer--block {
		padding: 0 100px;
		display: flex;

		.o-container {
			max-width: 1188px;
			margin: unset;
			margin-left: auto;
			margin-right: auto;
			padding: 0;
		}

		@media ${device.ipadLandscape} {
			padding: 0 46px !important;
			display: block;
			margin-top: -1px;
			max-width: 718px;

		}

		@media ${device.laptopMedium} {
			display: block;
			padding: 0;
		}

		.footer-block--row {
			display: flex;
			img {
				margin: auto;
				text-align: center;
				display: block;
				padding: 10px 0 20px 0;
				height: 100px;
				width: auto;
			}

			@media ${device.laptopMedium} {
				display: block;
			}

			.footer-inner-block {
				border-radius: 10px;
				margin: 0 15px;
				text-align: center;
				position: relative;
				border-radius: 5px;
				background-color: #fff;
				box-shadow: 0px 2px 4px rgb(17 17 17 / 16%);
				padding: 25px 20px 120px;
				margin: 0 15px;
				z-index: 992;

				@media ${device.laptopMedium} {
					width: 100%;
					padding-left: 20px;
					padding-right: 20px;
					padding-bottom: 20px;
					margin-bottom: 20px;
				}

				@media ${device.ipadLandscape} {
					padding-bottom: 30px !important;
					width: 100%;
					margin: 30px auto 0;
				}

				&:first-child {
					margin-left: 0;
					&:before {
						background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
					}
				}

				&:last-child {
					margin-right:0;

					&:before {
						background: linear-gradient(90deg, #3398ce 0%, #3398ce 25%, #17d0d4 74%, #06f2d7 100%);
					}
					.o-common-title {
						&:before {
							width: 90px;
						}
					}
					@media ${device.laptopMedium} {
						margin-left: 0;
					}
				}

				&:before {
					content: "";
					background: linear-gradient(90deg,#8000be 0%,#8000be 45%,#00FFD9 100%);
					width: 100%;
					height: 16px;
					position: absolute;
					top: 0;
					left: 0;
					border-radius: 5px 5px 0 0;
				}

				.o-common-title {
					font-weight: 700;
					font-size: 2.7rem;
					line-height: 3.8rem;
					color: #8000BE;
					text-align: center;
					margin-bottom: 30px;

					@media ${device.ipadLandscape} {
						font-size: 2rem;
						line-height: 2.2rem;
						font-weight: 500;
					}
				}

				.o-common-para {
					padding: 10px 5px 30px 5px;
					font-size: 2.2rem;
					line-height: 3.2rem;
					color: #000000;
					text-align: center;
					font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

					@media ${device.ipadLandscape} {
						font-size: 1.8rem;
						line-height: 2.6rem;
						font-weight: 500;
						padding: 0;
					}
				}

				a {
					box-shadow: 0px 3px 4px #d6d6d6;
					position: absolute;
					left: 25px;
					right: 25px;
					width: 90%;
					bottom: 45px;
					text-align: center;
					background: #8000BE;
					color: #fff;
					border: 1px solid #fff;
					cursor: pointer;
					letter-spacing: 0;
					border-radius: 6px;
					display: inline-block;
					vertical-align: top;
					overflow: hidden;
					padding: 10px 25px;
					font-size: 2rem;
					font-weight: 500;
					line-height: 2.4rem;
					text-decoration: none;
					transition: all 0.3s ease;
					z-index: 1;

					@media ${device.laptopMedium} {
						position: revert;
					}
					@media ${device.ipadLandscape} {
						position: relative;
						left: 0;
						bottom: 0;
						margin-top: 32px;
						width: 100%;
						display: block;
						padding: 10px;
						font-size: 18px;
					}

					&:hover {
						background: #fff;
						color: #8000BE;
						border-color: #fff;
						font-weight: 700;
						position: absolute;
						left: 25px;
						right: 25px;
						width: 90%;
						bottom: 45px;
						text-align: center;
						border: 1px solid #fff;

            @media ${device.laptopMedium} {
              position: revert;
            }
					}

					&:after {
						content: "";
						display: inline-block;
						width: 16px;
						height: 14px;
						position: relative;
						top: 2px;

						@media ${device.ipadLandscape} {
							padding: 10px 0 0;
							display: block;
							margin: auto;
							justify-content: center;
						}

						@media ${device.mobileMedium} {
							padding: 10px 0 0;
							display: block;
							margin: auto;
							justify-content: center;
						}
					}
        }

				.o-button {
					padding: 10px 25px;

					@media ${device.ipadLandscape} {
						padding: 10px;
					}
				}
			}

			.o-columns--two {
				@media ${device.mobileStart} {
					width: 50%;
				}

				@media ${device.ipadLandscape} {
					padding-bottom: 30px !important;
					width: 100%;
					margin: 30px auto 0;
				}
			}
    }
  }
`;

export const ConsiderationTesting = styled.div`

	.container-slider {
		max-width: 1188px;
		margin-left: auto;
		margin-right: auto;
		padding: 0 3rem;

		@media ${device.laptopMedium} {
			padding-left: 15px;
			padding-right: 15px;
			max-width: 718px;
		}

		.slick-slider {
			button {
				&.slick-arrow {
					display: none !important;
				}
			}
		}
	}

  .carouselHead {
    padding-top: 41px;
    padding-bottom: 41px;

		@media ${device.laptopMedium} {
			padding: 32px 0 0 0;
		}

    p {
      color: #000000;
      font-weight: 700;
      text-align: center;
      font-size: 4.2rem;
      line-height: 5rem;

			@media ${device.laptopMedium} {
				padding: 2px 20px 20px 20px;
				font-size: 2.5rem;
    		line-height: 3rem;
			}
    }
  }
  .carouselSlides {
		flex: 0 0 100%;
		max-width: 100%;
		width: 100%;
		min-height: 1px;
		padding-right: 30px;
		padding-left: 30px;
		padding-top: 8rem;

		@media ${device.mobileMedium} {
			padding-right: 0;
			padding-left: 0;
		}

		.btn-wrapper-slider {
			position: relative;
			display: flex;
			justify-content: center;
			margin-top: 20px;
		}
		.button {
			font-size: 2.2rem;
			font-weight: 500;
			line-height: 2.6rem;
			color: #fff;
			box-shadow: 0px 3px 4px #d6d6d6;
			margin: 5px 10px;
			border: 1px solid #fff;
		}

		.generic-slider-no-image {
			position: relative;
			padding-top: 0;

			.swiper-pagination {
				bottom: unset;
				top: 20px;
				background-color: #00FFD9;
				height: 8px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				max-width: 968px;
				margin: auto;
				text-align: center;
				left: 0;
				right: 0;
				position: absolute;
				transition: 0.3s opacity;
				z-index: 10;

				@media ${device.laptopMedium} {
					width: 100%;
					top: 44px;
				}

				.swipper-texts1::after {
					content: "At the initial visit, perform a thorough ocular and medical history";

					@media ${device.ipadLandscape} {
						content: "";
					}
				}

				.swipper-texts2::after {
					content: "Obtain a pedigree";

					@media ${device.ipadLandscape} {
						content: "";
					}
				}

				.swipper-texts3::after {
					content: "Refer the patient for genetic counselling";

					@media ${device.ipadLandscape} {
						content: "";
					}
				}

				.swipper-texts4::after {
					content: "Work with the genetic counsellors to ensure appropriate genetic testing";

					@media ${device.ipadLandscape} {
						content: "";
					}
				}

				.swipper-texts5::after {
					content: "Support the patient through the result";

					@media ${device.ipadLandscape} {
						content: "";
					}
				}

				.swiper-progress-bar-fill {
					position: absolute;
					width: 0;
					height: 8px;
					z-index: -1;
					background-color: #7323CA;
				}

				.swiper-pagination-bullet {
					width: 68px;
					height: 68px;
					margin: 0px;
					opacity: 1;
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #00FFD9;
					cursor: pointer;
					border-radius: 100%;
					font-weight: 700;
					line-height: 4.7rem;
					font-size: 3.4rem;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #7323CA;

					@media ${device.laptopMedium} {
						width: 44px;
						height: 44px;
						font-size: 2.7rem;
					}

					&.active {
						background-color: #7323CA;
						color: #fff;

						&:before {
							color: #fff;
						}
					}

					&:before {
						font-weight: 700;
						line-height: 4.7rem;
						font-size: 3.4rem;
						display: flex;
						justify-content: center;
						align-items: center;
						color: #7323CA;
					}

					&:after {
						font-weight: 500;
						line-height: 2.6rem;
						font-size: 2.2rem;
						display: flex;
						justify-content: center;
						align-items: center;
						color: #8000BE;
						position: absolute;
						width: 188px;
						top: 80px;

						@media ${device.laptopair2max} {
							width: 200px;
						}

            @media ${device.ipadLandscapemin} {
              width: 200px;
              padding-right: 50px;
            }

            @media ${device.smalldeskstrt} {
              width: 200px;
              padding-right: 30px;
            }

						@media ${device.laptopMedium} {
							content: "";
						}
					}
				}

				.swiper-progress-bar-fill {
					position: absolute;
					width: 0;
					height: 8px;
					z-index: -1;
					background-color: #7323CA;
				}
  		}
		}
		.button {
			font-size: 2.2rem;
			font-weight: 500;
			line-height: 2.6rem;
			color: #fff;
			box-shadow: 0px 3px 4px #d6d6d6;
			margin: 5px 10px;
			border: 1px solid #fff;
			width: 145px;
			height: 48px;
			background-color: #7323CA;
			position: relative;
			display: table-cell;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			cursor: pointer;
			margin: 0px 15px 30px;
			padding: 0 0;

			@media ${device.laptopMedium} {
				width: 137px;
			}

			&.back-btn {
				&:before {
					content: "";
					display: inline-block;
					height: 17px;
					width: 17px;
					transform: rotate(180deg);
					background-repeat: no-repeat;
					position: relative;
					top: 1px;
				}
				&:hover {
					background: #fff;
					color: #8000BE;
					border-color: #fff;
					font-weight: 700;
				}
			}

			&.forward-btn {
				&:after {
					content: "";
					display: inline-block;
					height: 17px;
					width: 17px;
					background-repeat: no-repeat;
					position: relative;
					top: 1px;
				}
				&:hover {
					background: #fff;
					color: #8000BE;
					border-color: #fff;
					font-weight: 700;
				}
			}
		}
	}
	.slick-slider {
		.item {
			max-width: 950px;
			margin-left: auto;
			margin-right: auto;

			.item__content {
				display: flex;
				margin: auto;
				padding-top: 245px;

				@media ${device.laptopair2max} {
					padding-top: 270px;
				}

				@media ${device.ipadLandscape} {
					padding-top: 80px;
				}

				@media ${device.laptopMedium} {
					display: block;
    			width: 100%;

				}

				.item__icon_slider {
					margin-right: 28px;
					@media ${device.laptopMedium} {
						margin: auto !important;
					}

					.media {
            margin-bottom: 16px;
						img {
							@media ${device.laptopMedium} {
								width: 30px;
								margin: 0 auto;
							}
						}
					}
				}

				.form-card-heading {
					font-size: 2.2rem;
					color: #8000BE;
					margin-bottom: 30px;
					display: none;

					@media ${device.laptopMedium} {
						margin: 0 auto 20px auto !important;
						display: block;
						text-align: center;
						margin-bottom: 20px;
						font-size: 1.8rem;
					}

					.item__icon {
						img {
							width: 64px;
							height: auto;
							margin: auto;
    					display: block;

							@media ${device.laptopMedium} {
								width: 41px;
							}
						}
					}
				}
				.o-paragraph {
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;

					@media ${device.ipadLandscapemin} {
						margin-top: 0;
					}

					ul {
						li {
              font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
						width: 100%;
						}
					}

					@media ${device.minimumDesk} {
						margin-top: 0;
					}

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 2.2rem;
						padding: 0 10px;
					}
				}

				.wizard-paragraph {
					font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

					@media ${device.laptopMedium} {
						text-align: center;
					}

					span {
						font-weight: normal;
					}

					@media ${device.laptopMedium} {
    				font-size: 1.8rem;
						line-height: 2.2rem !important;
            padding-bottom: 20px;
						max-width: 634px;
						margin: auto;
					}
				}

				ul {
					margin-top: 0;
					margin: auto;
					display: table;
					text-align: left;
					list-style: none;

					li {
						padding: 8px 0 8px 25px;
						line-height: 3rem;
						display: inline-block;
    				position: relative;
						font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

						&:before {
							content: "";
							position: absolute;
							background-color: #8000BE;
							width: 8px;
							height: 8px;
							top: 20px;
							margin: auto;
							left: 0;
						}
					}
				}
			}
		}
	}
`;
