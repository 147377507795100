import React from 'react'
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout";
import { graphql } from 'gatsby'
import { PageWrapper, HTMLRenderer } from '@retina-packages/retina-theme-bootstrap';
import SimpleSlider from '@retina-packages/retina-theme-bootstrap/packages/components/Carousels/SimpleSlider'
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders'
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import {
  ConsiderationsForTesting,
  FooterCalloutContainer,
  ConsiderationTesting
} from './styles'
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import carouselSlideBuilder from '@retina-packages/retina-theme-bootstrap/packages/helpers/carouselSlideBuilder'
import { hcpCFT } from './constants';
import retinaConfig from '../../../utils/retinaConfigs';
import { deriveMultiLogo, deriveSiteMenus, fullHTMLDataBuilder } from '../../../utils';

/**
 * Rendering Considerations for Testing page template
 *
 * @param props Props
 * @returns  void
 */
const ConsiderationsTestingTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsCFT = props.pageContext
  const htmlStaticFilesCFT = {
    nonSVGImages: pgContextsCFT.nonSVGImages,
    svgMediaImages: pgContextsCFT.svgMediaImages,
    allMediaDocument: pgContextsCFT.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: hcpCFT.mainMenu, siteFooterMenu: hcpCFT.footerMenu, addHCPPrefix: retinaConfig.addHcpPrefix });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  //Banner Component data
  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = hcpCFT.footer
  const exitPopupTitle = hcpCFT.exitPopup
  const hcpSwitcherTitle = hcpCFT.hcpSwitcher
  const topNavTitle = hcpCFT.topNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs,

  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle});

  // Retina Canada HCP - "Considerations for Testing" page - "American Academy of Ophthalmologists" Callout component
  const americanAcademyHtmlData = fullHTMLDataBuilder({blocks: blocks, title: hcpCFT.americanAcademy})

  //Learn More About component data
  const learnHtmlData = fullHTMLDataBuilder({blocks: blocks, title: hcpCFT.learnMore})
    //Callout component section data
  const calloutHtmlData = fullHTMLDataBuilder({blocks: blocks, title: hcpCFT.navigationCallout})

  //Considerations for testing reference data
  const hcpHomeReference = {'data': fullHTMLDataBuilder({blocks: blocks, title: hcpCFT.footerReference})}

  // Slider component data
  const { carouselSlides, switcherTexts, count } = blocks.filter((inf: any) => inf.relationships.field_reusable_paragraph.label === hcpCFT.slider).map(
    (info: any) => {
      return carouselSlideBuilder({ info })
    }
  ).at(0)

  const bannerHTML = fullHTMLDataBuilder({blocks: blocks, title: hcpCFT.banner})

  const siteLogos = deriveMultiLogo({blocks: blocks, title: hcpCFT.siteLogo})

  const pagewrapper = "consideration-testing"

  return (
    <>
      {/* site is not accessible it shows "Access denied" error message */}
      <ConsiderationsForTesting className="hcpmobilewrapper hcp-consideration-container">
        {props.pageContext !== null && props.pageContext.metaInfo !== null && (
          <MetaTagContainer metaData={props.pageContext.metaInfo} />
        )}
        <Layout
          title={"siteTitle"}
          location={props.location}
          data={mainMenu}
          mdata={footerMenu}
          footerData={footerText}
          audience={audience}
          footref={hcpHomeReference}
          exitPopData={exitData}
          modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
          hcplinks={hcpSwitcher}
          topNavigation={topNavs}
          siteLogos={siteLogos}
          backToTopContent={retinaConfig.backToTopContent}
          staticMediaFiles={htmlStaticFilesCFT}
          showRefContent={retinaConfig.showRefContent}
          hideRefContent={retinaConfig.hideRefContent}
          hcpValidate={retinaConfig.hcpValidate}
          preIndexUrl = {retinaConfig.preIndexUrl}
          hcpHomeUrl={retinaConfig.hcpHomeUrl}
          hcpPrefix={retinaConfig.hcpPrefix}
          siteHomeUrl={retinaConfig.siteHomeUrl}
          gtmBackToTopLabel={hcpCFT.backToTopGTM}
          languageConfig={{... retinaConfig.langConfig, ...hcpCFT.redirectLangUrl}}
          {... {...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
          footerClassName={hcpCFT.footerClassName}
        >
          <PageWrapper className={`pagewrapper ${pagewrapper}`}>
            <MobileBanner className="mobile-banner clinical-trials-banner">
              <HTMLRenderer data={htmlStaticFilesCFT} html={bannerHTML} tagName='section' className='internal-banner o-column--full' />
            </MobileBanner>
            {/* Retina Canada HCP - "Considerations for Testing" page - "American Academy of Ophthalmologists" Callout component */}
            <div className='o-container-considerations'>
              <HTMLRenderer html={americanAcademyHtmlData} data={htmlStaticFilesCFT}/>
            </div>
            <ConsiderationTesting>
              <div className='container-slider'>
                <SimpleSlider
                  data={carouselSlides}
                  switcherTexts={switcherTexts}
                  count={count}
                  staticMediaFiles={htmlStaticFilesCFT}
                  showSteps={true}
                  stepContent={hcpCFT.stepContent}
                  { ... hcpCFT.sliderAttrs }
                />
              </div>
            </ConsiderationTesting>
            <HTMLRenderer html={learnHtmlData} data={htmlStaticFilesCFT}/>
            <FooterCalloutContainer className='o-container padding-cards-fix'>
              <div className='footer-block steptogenetic-footer--block'>
                <HTMLRenderer html={calloutHtmlData} data={htmlStaticFilesCFT}/>
              </div>
            </FooterCalloutContainer>

          </PageWrapper>
        </Layout>
      </ConsiderationsForTesting>
    </>
  )
}

export default ConsiderationsTestingTemplate

export const pageQuery = graphql`
  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... CFTQuery
      }
    }
  }
`

