export const hcpCFT: any = {
  mainMenu: "Main Navigational Menu HCP GCSO",
  footerMenu: "Footer Navigation Menu HCP GCSO",
  footer: "Retina Global Footer HCP - Canada",
  exitPopup: "Retina Canada HCP Exit Popup HTML",
  hCPSwitcher: "HCP Switcher Modal Pop Up",
  topNav: "Top Navigational Menu HCP - Canada",
  footerCallout: "HCP Homepage Footer Navigation Callout HTML - Canada",
  siteLogo: "Site logos HCP",
  americanAcademy: "American Academy - Considerations For Testing",
  learnMore: "Learn More About - Considerations For Testing",
  navigationCallout: "Navigational Callout - Considerations For Testing",
  footerReference: "Considerations for Testing HCP - References",
  slider: "Slider Section - Considerations For Testing",
  banner: "Banner - HTML - Consideration Testing - HCP - Canada",
  stepContent: "Step",
  redirectLangUrl: {
    "redirectUrl": {
      "en": "/hcp/considerations-for-testing",
      "fr": "/fr/hcp/considerations-for-testing"
    }
  },
  backToTopGTM: "Back to top - considerations for testing",
  sliderAttrs: {
    sliderGTMElements: {
      'iconGTMs': [
        {
          'className': 'gtm-accordion',
          'aria-label': '1 At the initial visit, perform a thorough ocular and medical history'
        },
        {
          'className': 'gtm-accordion',
          'aria-label': '2 Obtain a pedigree'
        },
        {
          'className': 'gtm-accordion',
          'aria-label': '3 Refer the patient for genetic counselling'
        },
        {
          'className': 'gtm-accordion',
          'aria-label': '4 Work with the genetic counsellors to ensure appropriate genetic testing'
        },
        {
          'className': 'gtm-accordion',
          'aria-label': '5 Support the patient through the result'
        },
        {
          'className': 'gtm-accordion',
          'aria-label': '6 Continue evaluations'
        }
      ],
      'prevBtnGTMs': [
        {

        }
      ],
      'nextBtnGTMs': [
        {

        }
      ]
    }
  },
  footerClassName: "considerations-for-testing-footer"
}
